<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  padding: 30px;
  font-size: 20px;
}
.ant-checkbox-group-item {
  margin-right: 50px !important;
}
.ant-tabs-ink-bar {
  width: 200px !important;
}
.ant-tabs-tab {
  width: 200px !important;
  text-align: center !important;
}
.ant-form-item-label {
  text-align: left !important;
}
</style>
