import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
  mode: "history", //去掉#号
  routes: [
    {
      path: "/",
      redirect: "/list",
    },
    {
      path: "/list",
      name: "List",
      component: (resolve) => require(["../views/list/index.vue"], resolve),
    },
    {
      path: "/nodeDetails",
      name: "NodeDetails",
      component: (resolve) =>
        require(["../views/nodeDetails/index.vue"], resolve),
    },
    {
      path: "/userDetails",
      name: "UserDetails",
      component: (resolve) =>
        require(["../views/userDetails/index.vue"], resolve),
    },
  ],
});
